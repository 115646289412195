<template>
  <div
    class="small-profile"
    @click="$router.push('/user/' + userData.username)">
    <div class="avatar-container">
      <img
        class="small-image"
        v-if="hasImage"
        :src="userData.thumbnail"
        alt="" />
      <img
        class="small-image default-profile"
        v-else
        src="../assets/svg/temp-default-profile.svg"
        alt="" />
    </div>
    <div class="alias-container">
      <span class="alias text">{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Small Profile",
  props: {
    userData: Object,
  },
  computed: {
    hasImage() {
      return this.userData.thumbnail !== "" && this.userData.thumbnail !== null;
    },
    name() {
      return this.userData.alias || this.userData.username;
    },
  },
};
</script>

<style lang="scss" scoped>
.small-profile {
  background-color: $grey-200;
  display: flex;
  flex-direction: row;
  border-radius: $border-radius;
}
.avatar-container {
  overflow: hidden;
  border-radius: $border-radius;
  width: 5rem;
  height: 5rem;
  & > * {
    height: 100%;
    width: auto;
    aspect-ratio: 1;
    object-fit: cover;
  }
}
.alias-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  & > * {
    font-size: 1.3rem;
  }
}
.text {
  color: $black;
  font-size: auto;
  font-weight: 700;
}
.default-profile {
  padding: $item-margin;
  background-color: $white;
}
@media screen and (max-width: $switch-width) {
}
</style>
