<template>
  <div class="main-post">
    <div class="top-row">
      <h4 class="title">{{ postData.title }}</h4>
      <small-profile
        class="small-profile"
        :userData="postData.author"></small-profile>
    </div>
    <div class="second-row">
      <div class="tag-pill-box">
        <tag-pill
          class="clickable"
          v-for="tag in postData.tags"
          :key="tag.name"
          :name="tag.name"
          :color="tag.color"
          :fontSize="1.1"
          @click.stop="$router.push('/tag/' + tag.name)"></tag-pill>
      </div>
      <small-socials-box
        class="socials-box"
        :likes="postData.likes"
        :followers="postData.followers"
        :name="postData.title"
        :id="postData.id"></small-socials-box>
    </div>
    <div class="description-container" v-if="postData.description !== ''">
      <p class="description text">{{ postData.description }}</p>
    </div>
    <div class="post-sections-container" v-if="postData.album">
      <div
        v-for="section in postData.album.sections"
        :key="section.id"
        class="section-container">
        <div
          class="update-title-container"
          v-if="section.is_beginning_of_update">
          <div class="update-icon-container"><h3 class="text">Update</h3></div>
          <h3 class="text update-date-text">
            posted {{ getDateText(section.upload_time) }}
          </h3>
        </div>
        <post-view-segment
          :image="section.image"
          :externalURL="section.external_url"
          :text="section.content"></post-view-segment>
      </div>
    </div>
    <small-socials-box
      :likes="postData.likes"
      :followers="postData.followers"
      :name="postData.title"
      :id="postData.id"></small-socials-box>
  </div>
</template>

<script>
import TagPill from "@/components/basic/TagPill.vue";
import PostViewSegment from "./PostViewSegment.vue";
import { textStylingMixin } from "@/mixins/textStylingMixin";
import SmallProfile from "../SmallProfile.vue";
import SmallSocialsBox from "../socials/SmallSocialsBox.vue";
export default {
  name: "PostViewMainContainer",
  props: {
    postData: Object,
  },
  mixins: [textStylingMixin],
  components: {
    TagPill,
    PostViewSegment,
    SmallProfile,
    SmallSocialsBox,
  },
};
</script>

<style lang="scss" scoped>
.main-post {
  padding: $navbar-padding;
  border-radius: $border-radius;
  margin: $item-margin 0;
  counter-reset: update;
}
.title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 2.5rem;
  margin: 0;
  margin-top: 0.5rem;
  color: $black;
  margin-left: $item-margin;
  margin-top: $item-margin;
}
.description-container {
  background-color: $grey-200;
  border-left: $primary-300 solid 2px;
  min-height: 3rem;
  padding: $item-margin;
  margin: $item-margin;
}
.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.small-profile {
  align-self: flex-start;
  height: 5rem;
  width: 20rem;
  margin-right: $item-margin;
}
.socials-box {
  width: 20rem;
}
.second-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttons-container {
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
  margin-right: $item-margin;
  & > * {
    height: 2.3rem;
  }
}
.text {
  color: $black;
  font-weight: 400;
}
.tag-pill-box {
  display: flex;
  padding-left: 2rem;
  column-gap: 0.5rem;
  margin: 1rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: $item-margin;
  max-width: 50%;
}
.update-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $grey-100;
  margin: $item-margin;
  border-radius: $border-radius;
  padding: $item-margin;
}
.update-icon-container {
  display: flex;
  align-items: center;
  background-color: $primary-300;
  padding: 0.5rem 1rem;
  border-radius: $border-radius;
  font-size: 1.5rem;
  & > * {
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
    &::after {
      content: " #" counter(update);
    }
  }
  counter-increment: update;
}
.update-date-text {
  justify-self: bottom;
  align-self: bottom;
  margin: 0;
  margin-top: auto;
  font-size: 0.9rem;
  color: $primary-700;
}
.section-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: $switch-width) {
  .top-row {
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
  }
  .small-profile {
    align-self: center;
    margin-bottom: 2rem;
  }
  .top-row::after {
    content: "Posted By:";
    font-weight: 200;
    padding: 0.5rem;
  }
  .update-icon-container {
    padding: 0.1rem 0.5rem;
    border-radius: $border-radius;
    font-size: 1rem;
  }
  .update-date-text {
    font-size: 0.7rem;
  }
  .second-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tag-pill-box {
    max-width: none;
  }
}
</style>
