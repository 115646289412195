<template>
  <div class="single-post-view" v-if="postData !== undefined">
    <post-view-main-container :postData="postData"></post-view-main-container>
    <div class="footer">
      <div
        class="buttons-container-row"
        v-if="$store.state.userData.username === postData.author.username">
        <button class="button button-info" @click="editPost()">edit</button>
        <button class="button button-primary" @click="goToUpdatePost()">
          post an update
        </button>
        <button class="button button-danger" @click="deletePost()">
          delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PostViewMainContainer from "../components/posts/PostViewMainContainer.vue";
export default {
  name: "ViewPost",
  components: {
    PostViewMainContainer,
  },
  data() {
    return {
      postData: undefined,
      respondToRouteChanges: true,
    };
  },
  methods: {
    checkForPostData() {
      this.$store.commit("setLoading", true);
      if (this.$store.state.nextViewPost !== undefined) {
        this.postData = this.$store.state.nextViewPost;
        document.title = this.postData.title + " | icandoathing";

        this.updateRouteSlug();
        this.$store.commit("setLoading", false);
        axios.post("api/v1/posts/id/" + this.$route.params.id + "/", {
          incrementViews: true,
        });
      } else {
        axios
          .get("api/v1/posts/id/" + this.$route.params.id + "/")
          .then((res) => {
            console.log(res);
            this.postData = res.data;
            document.title = this.postData.title + " | icandoathing";

            this.updateRouteSlug();
            this.$store.commit("setLoading", false);
            axios.post("api/v1/posts/id/" + this.$route.params.id + "/", {
              incrementViews: true,
            });
          })
          .catch((err) => {
            //TODO show custom message for not found
            console.log(err);
            this.$notify({
              title: "oops",
              body: "no post found",
              type: "danger",
            });
            this.$store.commit("setLoading", false);
            this.$router.push("/");
          });
      }
    },
    updateRouteSlug() {
      if (!this.$route.params.slug) {
        let path = this.$route.path;
        if (path.endsWith("/")) path = path.slice(0, -1);
        history.replaceState({}, null, path + "/" + this.postData.slug);
      }
    },
    goToUpdatePost() {
      this.$store.commit("setViewPost", this.postData);
      this.$router.push("/updatepost/" + this.postData.id);
    },
    editPost() {
      this.$store.commit("setViewPost", this.postData);
      this.$router.push("/editpost/" + this.postData.id);
    },
    deletePost() {
      if (confirm("Are you sure you want to delete the post?")) {
        console.log("hi");
        this.$store.commit("setLoading", true);
        axios
          .delete("/api/v1/posts/delete/" + this.postData.id + "/")
          .then((res) => {
            console.log(res);
            this.$router.push("/");
            this.$store.commit("setLoading", false);
          })
          .catch((e) => {
            console.log(e);
            this.$store.commit("setLoading", false);
          });
      }
    },
  },
  beforeMount() {
    this.checkForPostData();
  },
  mounted() {},
  beforeUnmount() {
    if (
      this.$router.currentRoute.value.name &&
      !this.$router.currentRoute.value.name.startsWith("UpdatePost")
    ) {
      this.$store.commit("clearViewPost");
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.postData = undefined;
    this.checkForPostData();
    next();
  },
};
</script>

<style lang="scss" scoped>
.single-post-view {
  width: 100%;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
