<template>
  <div class="small-socials-box">
    <!-- <div class="followers-box info-box" @click.prevent="onFollowClick">
      <p class="followers-text info-box-text text">
        <span class="followers-num number">{{ styledFollowers }}</span>
        &nbsp;{{ numOfFollowers === 1 ? "follower" : "followers" }}
      </p>
      <plus-icon
        class="small-icon plus-icon"
        :class="[followed ? 'followed' : '']"></plus-icon>
    </div> -->
    <div class="likes-box info-box" @click.prevent="onHeartClick">
      <p class="likes-text info-box-text text">
        <span class="likes-num number"> {{ styledLikes }} </span>&nbsp;{{
          numOfLikes === 1 ? "like" : "likes"
        }}
      </p>
      <heart-icon
        class="small-icon heart-icon"
        :class="{ liked: liked, 'liked-animate': animate }"></heart-icon>
    </div>
    <share-button
      class="share-button"
      :name="name"
      shareType="post"
      :url="fullRoute"></share-button>
  </div>
</template>

<script>
// import PlusIcon from "vue-material-design-icons/PlusCircle.vue";
import HeartIcon from "vue-material-design-icons/Heart.vue";
import { socialsMixin } from "@/mixins/socialsMixin";
import ShareButton from "./ShareButton.vue";
export default {
  name: "SmallSocialsBox",
  components: {
    // PlusIcon,
    HeartIcon,
    ShareButton,
  },
  mixins: [socialsMixin],
  props: {
    likes: {
      type: Number,
      default: 0,
    },
    followers: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      liked: false,
      followed: false,
      numOfLikes: 0,
      numOfFollowers: 0,
      animate: false,
    };
  },
  computed: {
    styledLikes() {
      return this.numOfLikes > 999
        ? (this.numOfLikes / 1000).toPrecision(2) + "K"
        : this.numOfLikes;
    },
    styledFollowers() {
      return this.numOfFollowers > 999
        ? (this.numOfFollowers / 1000).toPrecision(2) + "K"
        : this.numOfFollowers;
    },
    fullRoute() {
      let route = this.$route;
      return new URL(route.href, window.location.href).href;
    },
  },
  methods: {
    onHeartClick() {
      if (this.$store.state.isAuthenticated) {
        if (this.liked) {
          if (this.id !== "") this.dislike(this.id);
          this.liked = false;
          this.numOfLikes--;
        } else {
          if (this.id !== "") this.like(this.id);

          this.animate = true;
          this.liked = true;
          this.numOfLikes++;
          setTimeout(() => {
            this.animate = false;
          }, 1000);
        }
      } else {
        this.$notify({
          title: "Please log in!",
          body: "Please log in to like this thing",
          icon: "",
          type: "warning",
        });
      }
    },
    onFollowClick() {
      console.log(this.followed);
      if (this.$store.state.isAuthenticated) {
        if (this.followed) {
          if (this.id !== "") this.unfollow(this.id);
          this.followed = false;
          this.numOfFollowers--;
        } else {
          if (this.id !== "") this.follow(this.id);
          this.followed = true;
          this.numOfFollowers++;
        }
      } else {
        this.$notify({
          title: "Please log in!",
          body: "Please log in to follow this thing",
          icon: "",
          type: "warning",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.isAuthenticated) {
      if (this.$store.state.userData.liked_posts.includes(this.$props.id)) {
        this.liked = true;
      }
    }
    if (this.$store.state.isAuthenticated) {
      if (this.$store.state.userData.following_posts.includes(this.$props.id)) {
        this.followed = true;
      }
    }
    this.numOfLikes = this.likes;
    this.numOfFollowers = this.followers;
    //TODO add dynamic rechecks for likes
  },
};
</script>

<style lang="scss" scoped>
.small-socials-box {
  background-color: $grey-200;
  display: flex;
  flex-direction: row;
  border-radius: $border-radius;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  padding: $item-margin;
  column-gap: $item-margin;
  margin: $item-margin;
}
.info-box {
  display: flex;
  color: $white;
  justify-content: right;
  padding-right: 1rem;
  align-items: center;
  background-color: $grey-300;
  width: 10rem;
  height: 2rem;
  border-radius: $border-radius;
}
.share-button {
  display: flex;
  justify-content: center;
  column-gap: 0.3rem;
  align-items: center;
  width: 10rem;
  height: 2rem;
  font-size: 1rem;
}
.small-icon {
  padding: $item-margin;
}
.followed {
  color: $purple !important;
}
.text {
  color: $black;
  user-select: none;
}
.info-box {
  cursor: pointer;
}
@media screen and (max-width: $switch-width) {
  .small-socials-box {
    border-radius: 0 $border-radius $border-radius 0;
  }
}
</style>
